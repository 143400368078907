console.log('**** MAIN ****')
import { createApp } from 'vue'

import { createPinia } from 'pinia'
import i18n from '@/i18n/i18n-setup.js'
import vuetify from '@/plugins/vuetify.js'
import Vue3Toastify from 'vue3-toastify'
import App from './App.vue'
import router from './router'
import dayjs from '@/plugins/dayjs'

import { Buffer } from 'node:buffer'

import '@/assets/css/main.css'
import '@mdi/font/css/materialdesignicons.css'

import 'vue3-toastify/dist/index.css'

window.Buffer = Buffer

const app = createApp(App)

const pinia = createPinia()

// import Toast, { TYPE } from 'vue-toastification'
// Import the CSS or use your own!
// import 'vue-toastification/dist/index.css'
/*
const toastOptions = {
  timeout: 4000,
  maxToasts: 3,
  newestOnTop: true,
  showCloseButtonOnHover: true,
  position: 'top-center',
  transition: 'Vue-Toastification__fade',
  [TYPE.ERROR]: {
    timeout: 8000,
    hideProgressBar: true
  }
}
*/
app.config.globalProperties.$date = dayjs

// app.use(updateSW)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(vuetify)
// app.use(Toast, toastOptions)
app.use(Vue3Toastify, {
  autoClose: 4000,
  limit: 5
})

app.mount('#app')
